import React, { Component } from "react"

import Page from "../components/Page"
import Centerer from "../components/Centerer"

class LambdaPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      challenges: [],
    }
  }

  componentDidMount() {
    fetch(`/.netlify/functions/getChallenges`)
      .then(response => {
        if (response.status !== 200) {
          console.log(
            "Looks like there was a problem. Status Code: " + response.status
          )
          return
        }

        response.json().then(({ challenges }) => {
          this.setState({
            challenges,
          })
        })
      })
      .catch(err => {
        console.log("Fetch Error", err)
      })
  }

  render() {
    console.warn("### challenges", this.state.challenges)
    return (
      <Page>
        <Centerer>
          <h1>Welcome!</h1>
        </Centerer>
      </Page>
    )
  }
}

export default LambdaPage
